import './Main.scss';
import { Grid, Typography, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';

function Prices() {
    const [prices, setPrices] = useState(null);

    const getPrices = async () => {
        const prices = await fetch('data/prices.json', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        });
        return prices.json();
        }

    useEffect(() => {
        getPrices().then(data => {
            data.rent.sort((a, b) => (a.type > b.type) ? 1 : 0);
            setPrices(data);
        });
    }, []);

  return (
    <Grid item container className="grey-background" justifyContent="center">
        <Grid item container id="price-section" className="section">
            <Grid item container spacing={2}>
                <Grid item>
                    <Typography variant='h4' fontWeight={600}>Árak 2024</Typography>
                </Grid>
                <Grid item container direction="row">
                    <Grid item xs={4}>
                        <Typography></Typography>
                    </Grid>
                    <Grid item container justifyContent="center" xs={2}>
                        <Typography className="price-title"><b>1-2 fő</b></Typography>
                    </Grid>
                    <Grid item container justifyContent="center" xs={2}>
                        <Typography className="price-title"><b>3-4 fő</b></Typography>
                    </Grid>
                    <Grid item container justifyContent="center" xs={2}>
                        <Typography className="price-title"><b>5 fő</b></Typography>
                    </Grid>
                    <Grid item container justifyContent="center" xs={2}>
                        <Typography className="price-title"><b>Kisállat</b></Typography>
                    </Grid>
                </Grid>
                {prices && prices.rent && prices.rent.map(rent => {
                    return (    
                        <Grid item container direction="row" key={rent.type}>
                            <Grid item container alignItems="center" xs={4}>
                                <Typography className="price-title"><b>{rent.title}</b></Typography>
                            </Grid>
                            <Grid item container justifyContent="center" alignItems="center" xs={2} className="price-item-container">
                                <Paper elevation={3} className="price-item">
                                    <Grid container direction="column">
                                        <Typography className="price-text">{rent.prices.ONE_TWO} Ft/éj</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item container justifyContent="center" alignItems="center" xs={2} className="price-item-container">
                                <Paper elevation={3} className="price-item">
                                    <Grid container direction="column">
                                        <Typography className="price-text">{rent.prices.THREE_FOUR} Ft/éj</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item container justifyContent="center" alignItems="center" xs={2} className="price-item-container">
                                <Paper elevation={3} className="price-item">
                                    <Grid container direction="column">
                                        <Typography className="price-text">{rent.prices.FIVE} Ft/éj</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {rent.prices.PET && (
                                <Grid item container justifyContent="center" alignItems="center" xs={2} className="price-item-container">
                                    <Paper elevation={3} className="price-item">
                                        <Grid container direction="column">
                                            <Typography className="price-text">{rent.prices.PET} Ft/éj</Typography>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    );
                })}
                <Grid item id="price-note">
                    <Typography>*<b>Tavaszi és őszi szezonban</b> (március 15. - május 31. / szeptember 1. – október 31.) minimum 2 éjszaka foglalható.</Typography>
                    <Typography>*<b>Nyári szezonban</b> (június 1.- augusztus 31.) minimum 3 éjszaka foglalható.</Typography>
                </Grid>
                <Grid item container direction="column" spacing={2}>
                    <Grid item>
                        <Typography>
                            Áraink forintban értendőek és tartalmazzák az ÁFÁ-t. A ház maximum 5 férőhelyes, de megoldható + 2 fő pótágyazása.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Az ár tartalmazza a ház és kert kizárólagos használatát, ágyneműt, törölközőt, kávét, takarítást.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Az idegenforgalmi adó díja 500 Ft/fő/éj 18 év felett.
                        </Typography>
                    </Grid>
                    <Grid item>                
                        <Typography>
                            Hosszabb tartamú foglalás esetén kérjen egyedi ajánlatot.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Prices;