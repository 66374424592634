import './Main.scss';
import { Grid, Typography } from '@mui/material';
import React from 'react';

function Location() {
  return (
    <Grid item container className="grey-background" justifyContent="center">
        <Grid item container className="section">
            <Grid item container spacing={4} className="location-container">
                <Grid item container xs={5} className="left-side">
                    <Typography variant='h4' fontWeight={600} className="title">Megközelítés</Typography>
                    <Typography>
                        Személyautóval Badacsonytördemic felől lehetséges egy egysávos, hegyi, erdei úton. Az út minősége nem túl
                        jó, aszfaltos, murvás. Szembe érkező autók esetén vannak félreálló szakaszok, lehet hogy vissza kell
                        tolatni. Lesz egy Y elágazás zsákutca táblával feltüntetve, ezen kell behajtani. Kétoldalt bazalt kővel
                        van kirakva, szűk lesz ez a szakaszt, de az autó elfér. Gyalogosan a központból Badacsony állomásról a
                        Kisfaludy úton majd az erdőn keresztül piros turista útvonalon lehet feljutni a vendégházhoz.
                    </Typography>
                </Grid>
                <Grid item xs={7} className="right-side">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                        <iframe width="100%" height="350" id="gmap_canvas" src="https://www.google.com/maps/embed/v1/place?q=Badacsonytomaj,+Szőlőskert+Vendégház+Badacsony,+Rodostói+Way,+Hungary&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}>
                        </iframe>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Location;