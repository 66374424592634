import './Main.scss';
import { Grid } from '@mui/material';
import React from 'react';
import AboutHouse from './AboutHouse';
import Equipment from './Equipment';
import Location from './Location';
import Prices from './Prices';
import Gallery from './Gallery';
import Reservation from './Reservation';

function Main(props) {
  const { setImage } = props;

  return (
    <Grid container justifyContent="center">
      <AboutHouse setImage={setImage} />
      <Equipment />
      <Gallery setImage={setImage} />
      <Prices />
      <Reservation />
      <Location />
    </Grid>
  );
}

export default Main;