import './Main.scss';
import { Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { InsertEmoticonRounded } from '@mui/icons-material';

function Gallery(props) {
  const { setImage } = props;
  const NUMBER_OF_IMAGES_GALLERY = 14;
  const galleryFolder = "images/gallery/";
  const [allImages, setAllImages] = useState([]);
  const [imageListCols, setImageListCols] = useState(5);
  const [imageRowHeight, setImageRowHeight] = useState(200);

  useEffect(() => {
    const galleryImageSrcs = [];
    for (let i = 0; i < NUMBER_OF_IMAGES_GALLERY; i++) {
      galleryImageSrcs.push(galleryFolder + 'img_' + (i + 1) + '.jpg');
    }
    setAllImages(galleryImageSrcs);
  }, [])

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth >= 900) {
        setImageListCols(5);
        setImageRowHeight(200);
      } else if (window.innerWidth < 900 && window.innerWidth >= 800) {
        setImageListCols(4);
        setImageRowHeight(200);
      } else if (window.innerWidth < 800 && window.innerWidth >= 600) {
        setImageListCols(3);
        setImageRowHeight(200);
      } else if (window.innerWidth < 600 && window.innerWidth >= 400) {
        setImageListCols(2);
        setImageRowHeight(200);
      } else {
        setImageListCols(1);
        setImageRowHeight(300);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Grid item container className="section">
        <Grid item container direction="row" spacing={4}>
            <Grid item container>
                <Typography variant='h4' fontWeight={600}>Galéria</Typography>
            </Grid>
            <Grid item>
              <ImageList cols={imageListCols} rowHeight={imageRowHeight} variant="quilted">
                {allImages.map((item) => (
                  <ImageListItem key={item}>
                    <img
                      src={`${item}`}
                      srcSet={`${InsertEmoticonRounded}`}
                      alt={item.title}
                      loading="lazy"
                      className="image"
                      onClick={() => setImage(item)}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Gallery;