import './Main.scss';
import { Grid, Typography, ImageList, ImageListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

function AboutHouse(props) {
  const { setImage } = props;
  const NUMBER_OF_IMAGES_ABOUTHOUSE = 4;
  const abouthouseFolder = "images/abouthouse/";
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    const abouthouseImageSrcs = [];
    for (let i = 0; i < NUMBER_OF_IMAGES_ABOUTHOUSE; i++) {
      abouthouseImageSrcs.push(abouthouseFolder + 'img_' + (i + 1) + '.jpg');
    }
    setAllImages(abouthouseImageSrcs);
  }, [])

  return (
    <Grid item container className="section">
        <Grid item container spacing={4} className="abouthouse-container">
            <Grid item container xs={6} className="left-side">
                <Typography variant='h4' fontWeight={600} className="title">A házról</Typography>
                <Typography>
                Vendégházunk a Balaton Északi partján Badacsonytomajon, Badacsony hegy tetején a legfelső úton
                helyezkedik el a Kisfaludy Házhoz közel. A ház hangulatát a kertben található 1000 m2 szőlő ültetvény,
                valamint a Balatonra nyíló csodálatos panoráma teszi különlegessé.
                </Typography>
                <Typography>Az épület eredetileg présház volt és az 1860 években épült, majd 1980-as években bővítették ki.</Typography>
                <Typography>
                A vendégház 70 m2-es, 2018- ban kezdtük meg a felújítását, törekedtünk arra hogy a ház külsőleg megtartsa
                az eredeti jellegét amibe beleszerettünk. Belső kialakításánál a régi és az új „vintage” stílust
                próbáltuk ötvözni.
                </Typography>
               
            </Grid>
            <Grid item container xs={6} className="right-side">
              <Grid item>
              <ImageList sx={{ height: 345 }} cols={2} rowHeight={170}>
              {allImages.map((item) => (
                  <ImageListItem key={item}>
                    <img
                        src={`${item}?w=170&h=170&fit=crop&auto=format`}
                        srcSet={`${item}?w=170&h=170&fit=crop&auto=format&dpr=2 2x`}
                        alt={item}
                        loading="lazy"
                        className="image"
                        onClick={() => setImage(item)}
                    />
                  </ImageListItem>
              ))}
              </ImageList>
              </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default AboutHouse;