import './Main.scss';
import { Grid, Typography } from '@mui/material';
import React from 'react';

function Reservation() {
  return (
    <Grid item container className="section">
        <Grid item container spacing={4}>
            <Grid item container>
                <Typography variant='h4' fontWeight={600}>Foglalás</Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <iframe src="https://calendar.google.com/calendar/embed?src=szoloskertbadacsony%40gmail.com&ctz=Europe%2FBudapest&wkst=2&bgcolor=%23ffffff&showTitle=0&showPrint=0&showTz=0&showTabs=0&showCalendars=0&showDate=1&color=%2305668d" frameBorder="0" scrolling="no" className='calendar'></iframe>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography>
                  A foglalás minden esetben csak írásos visszaigazolás követően érvényes. Az utalásra minden esetben a visszaigazoló e-mail megérkezéstől számítva 24 óra áll rendelkezésre. Ha a tranzakció nem valósul meg, a foglalás automatikusan törlésre kerül.
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  A foglalás feltétele:
                </Typography>
                <ul>
                  <li>a foglaló befizetése. A foglaló, az eltöltött éjszakák 50%-a, mely összeget pontosan megírjuk visszaigazolásunkban.</li>
                  <li>személyes adatok megadása</li>
                  <li>hozzájárulás a személyes adatok kezeléséhez</li>
                </ul>
              </Grid>
              <Grid item>
                <Typography>
                A teljes összeget az érkezés előtti 15. napig kérjük átutalással megfizetni + az idegen forgalmi adót (500 Ft/fő/éj 18 év felett).
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Amennyiben a szállás az érkezés előtti 7. napon kerül lemondásra, a szolgáltatás 100%-a kerül kötbérként felszámolásra. A foglalás díjmentes lemondása az érkezést megelőző 15 napig lehetséges, az érkezést megelőző 8-14 napig a foglalási díj 50%-a a lemondási díj. A házat az érkezés napján 15:00-tól lehet elfoglalni és a távozás napján 10:00-ig kérjük átadni.
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Amennyibben károkozás történik, az okozott kár 100%-ban térítendő.
                </Typography>
              </Grid>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Reservation;
