import './Header.scss';
import { Grid, Link, Typography } from '@mui/material';
import { LocationOn, Phone, Email, Facebook } from '@mui/icons-material';
import React from 'react';

function Header() {
  return (
    <Grid>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap" className="header">
        <Grid item>
          <img class="logo" src="logo.png" alt="Szőlőskert Vendégház Badacsony" />
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <LocationOn className="icon" />
                <Link href="https://www.google.com/maps/place/Sz%C5%91l%C5%91skert+Vend%C3%A9gh%C3%A1z+Badacsony/@46.7949445,17.4914069,17z/data=!3m1!4b1!4m8!3m7!1s0x4769014556d8bb51:0x8a52cb1e3bfe36b3!5m2!4m1!1i2!8m2!3d46.7949408!4d17.4935955"
                  target="_blank" className="link" underline="none" color="initial">
                  <Typography className="white-text">8261 Badacsonytomaj, Rodostói út 7.</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <Phone className="icon" />
                <Typography className="white-text">+36 30 740 0692</Typography>
              </Grid>
            </Grid>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <Email className="icon" />
                <Link href="mailto:szoloskertbadacsony@gmail.com" className="link" underline="none" color="initial">
                  <Typography className="white-text">szoloskertbadacsony@gmail.com</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid item>
              <Link href="https://www.facebook.com/SzoloskertVendeghazBadacsony" target="_blank" className="link">
                <Facebook className="icon flex-icon" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
