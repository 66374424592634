import './App.scss';
import Header from './views/header/Header';
import Main from './views/main/Main';
import { createTheme, Dialog, Grid, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import PreviewDialogContent from 'views/main/PreviewDialogContent';

function App() {
  const NUMBER_OF_IMAGES_ABOUTHOUSE = 4;
  const abouthouseFolder = "images/abouthouse/";
  const NUMBER_OF_IMAGES_GALLERY = 14;
  const galleryFolder = "images/gallery/";
  const [allAbouthouseImages, setAllAbouthouseImages] = useState([]);
  const [allGalleryImages, setAllGalleryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [initialActiveStep, setInitialActiveStep] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
        'sans-serif',
      ].join(','),
    },
  });

  useEffect(() => {
    const abouthouseImageSrcs = [];
    for (let i = 0; i < NUMBER_OF_IMAGES_ABOUTHOUSE; i++) {
      abouthouseImageSrcs.push(abouthouseFolder + 'img_' + (i + 1) + '.jpg');
    }
    setAllAbouthouseImages(abouthouseImageSrcs);

    const galleryImageSrcs = [];
    for (let i = 0; i < NUMBER_OF_IMAGES_GALLERY; i++) {
      galleryImageSrcs.push(galleryFolder + 'img_' + (i + 1) + '.jpg');
    }
    setAllGalleryImages(galleryImageSrcs);
  }, [])

  const onPrivewClose = () => {
    setSelectedImage(null);
    setInitialActiveStep(null);
  }

  const updateSelectedImage = (image) => {
    const rightFolder = image.includes("abouthouse") ? allAbouthouseImages : allGalleryImages;
    const originalImage = image.includes("_small") ? image.replace("_small", "") : image;
    const imageIndex = rightFolder.findIndex(image => image === originalImage);
    if (imageIndex > -1) {
      setSelectedImage(rightFolder[imageIndex]);
      setInitialActiveStep(imageIndex);
    }
  }

  return (
    <ThemeProvider theme={theme}>
          <Grid className='header-background'>
            <Header />
          </Grid>
          <Main setImage={(img) => updateSelectedImage(img)} />
          <Grid className='footer-background'>
            <Header />
          </Grid>
          <Dialog open={!!selectedImage} fullScreen={true}>
            {selectedImage && <PreviewDialogContent images={selectedImage.includes("abouthouse") ? allAbouthouseImages : allGalleryImages} initialActiveStep={initialActiveStep} onPrivewClose={onPrivewClose} />}
          </Dialog>
    </ThemeProvider>
  );
}

export default App;
