import './dialog.scss';
import React, { useState, useEffect } from 'react';
import { Grid, DialogContent, IconButton, Button, MobileStepper, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function PreviewDialogContent(props) {
  const { images, initialActiveStep, onPrivewClose } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setActiveStep(initialActiveStep);
    setLoading(false);
  }, [initialActiveStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === images.length  - 1) {
        return 0;
      }
      return prevActiveStep + 1
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return images.length  - 1;
      }
      return prevActiveStep - 1
    });
  };

  return (
    <DialogContent id="preview-dialog-content">
      <Grid container direction="column" justifyContent="space-between" className="preview-dialog-content-container">
          <IconButton aria-label="close" id="preview-close-button" onClick={onPrivewClose}>
            <CloseIcon />
          </IconButton>
            <Grid item container justifyContent="center" alignItems="center" className="image-container">
              {loading
                ? <CircularProgress />
                : (
                <img
                    className="stepper-image"
                    src={images[activeStep]}
                    alt={images[activeStep]}
                />
                )
              }
            </Grid>
          <Grid item className="mobile-stepper-container">
            <MobileStepper
                steps={images.length}
                position="static"
                variant="dots"
                activeStep={activeStep}
                className="root"
                nextButton={images.length > 1 ? (
                  <Button size="small" onClick={handleNext} id="left-button">
                      <KeyboardArrowRightIcon />
                  </Button>
                ) : null
                }
                backButton={images.length > 1 ? (
                  <Button size="small" onClick={handleBack} id="right-button">
                      <KeyboardArrowLeftIcon />
                  </Button>
                ) : null
                }
            />
          </Grid>
        </Grid>
    </DialogContent>
  );
}

export default PreviewDialogContent;