import './Main.scss';
import { Grid, Typography } from '@mui/material';
import KitchenIcon from '@mui/icons-material/Kitchen';
import ShowerIcon from '@mui/icons-material/Shower';
import WeekendIcon from '@mui/icons-material/Weekend';
import BedIcon from '@mui/icons-material/Bed';
import DeckIcon from '@mui/icons-material/Deck';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import React from 'react';

function Equipment() {
  return (
    <Grid item container className="grey-background" justifyContent="center">
       <Grid item container className="section">
        <Grid item container spacing={1}>
            <Grid item>
                <Typography variant='h4' fontWeight={600}>Felszereltség</Typography>
            </Grid>
            <Grid item container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={4}>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <KitchenIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Konyha</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>főzőlap</li>
                                <li>hűtő</li>
                                <li>mikrohullámú sütő</li>
                                <li>mosogatógép</li>
                                <li>kenyér pirító</li>
                                <li>kotyogós kávéfőző</li>
                                <li>vízforraló</li>
                                <li>kávé</li>
                                <li>tea</li>
                                <li>fűszerek</li>
                                <li>etetőszék</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <WeekendIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Nappali</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>kanapé</li>
                                <li>cserépkályha</li>
                                <li>wifi, TV nincs, de van helyette társasjáték</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sm={8} md={4}>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <ShowerIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Fürdőszoba</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>zuhanyzó</li>
                                <li>hajszárító</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <BedIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Hálószobák</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>egymásból nyílnak a tetőtérben</li>
                                <li>kisgyermekek esetén biztosítani tudunk utazó ágyat</li>
                            </ul>                   
                        </Grid>
                    </Grid>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <BedIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Első hálószoba</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>1 db 160x200 cm francia ágy</li>
                                <li>1 db 90x200 cm egyszemélyes ágy</li>
                                <li>klimatizált (hűtésre, fűtésre)</li>
                            </ul>                    
                        </Grid>
                    </Grid>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <BedIcon className="icon-color" />
                            </Grid>
                            <Grid item alignItems="center">
                                <Typography variant="h5">Hátsó hálószoba</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>1 db 160x200 cm francia ágy</li>
                                <li>elektromos fűtőpanel</li>
                            </ul>            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sm={8} md={4}>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <OutdoorGrillIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Terasz</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>grillezési, bográcsozási lehetőség</li>
                                <li>az eszközöket és a fát biztosítjuk</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item container className="equipment-group">
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <DeckIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Alsó terasz</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>napozóágyak</li>
                                <li>fémvázas medence (júliustól-augusztus végéig)</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item container alignItems="center" spacing={2}>
                            <Grid item alignItems="center" className="flexItem">
                                <LocalParkingIcon className="icon-color" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5">Parkolás</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className="equipment-padding">
                            <ul>
                                <li>zárt udvaron belül két autó számára</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
       </Grid>
    </Grid>
  );
}

export default Equipment;